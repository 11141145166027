@import 'system/variables';
@import 'system/mixin';
* {
  box-sizing: border-box;
}
body {
  font-family: $ff;
  color: #565656;
  font-weight: 300;
  line-height: 1.6;
  font-size: 15px;

  @include respond-to(xs) {
    font-size: 16px;
  }
}
a {
  transition: $trans;
}
b,
strong {
  font-family: $ff;
  font-weight: 600;
}

img {
  max-width: 100%;
}

.site-width {
  max-width: 100%;
  width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}
.w-100 {
  width: 100%;
}
.page {
  min-height: 40vh;
  &__header {
    padding-top: 108px;
    position: relative;
    overflow: hidden;
    &-bg {
      position: absolute;
      width: 105%;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: url(/images/hero-bg.jpg) center center no-repeat;
      background-size: cover;
      filter: blur(3px);
      z-index: -1;
    }
  }
}
.text-center {
  text-align: center;
}
.padding {
  padding: 80px 0;
  @include respond-to(xs){
    padding: 40px 0;
  }
  &--top {
    padding: 80px 0 0 0;
    @include respond-to(xs){
      padding: 40px 0 0 0;
    }
  }
  &--bottom {
    padding: 0px 0 80px 0;
    @include respond-to(xs){
      padding: 0 0 40px 0;
    }
  }
}
.btn {
  background: #ff5400;
  border-radius: 0;
  color: #ffffff;
  font-family: $sec;
  font-size: 14px;
  font-weight: 400;
  border: none;
  text-transform: uppercase;
  text-align: center;
  padding: 0 30px;
  height: 45px;
  line-height: 45px;
  display: inline-block;
  transition: $trans;
  text-decoration: none;
  &--sm {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }
  &:hover {
    background: $dark;
    color: #fff;
  }
}
.title {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  font-family: $sec;
  font-size: 25px;
  color: #222222;
  position: relative;
  padding-bottom: 22px;
  margin-bottom: 25px;
  text-align: center;
  span {
    font-weight: inherit;
    color: $brand;
  }
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    width: 60px;
    height: 4px;
    border-top: 1px solid #ff5400;
    border-bottom: 1px solid #ff5400;
    margin-left: -30px;
  }
}
.list {
  &__item {
    font-size: 18px;
    margin-bottom: 7px;
    list-style: none;
    padding-left: 20px;
    position: relative;
    // font-family: $sec;
    font-weight: 100;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $dark;
      background: $brand;
      display: block;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.header {
  position: absolute;
  width: 100%;
  z-index: 9;
  .site-width {
    display: flex;
  }
  &__logo {
    padding: 0 0 0 15px;
    width: 15.89%;
    flex-shrink: 0;
    min-width: 150px;
  }
  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;;
  }
  &__top-line {
    background: $dark;
    display: flex;
    justify-content: flex-end;
    padding: 7px 0;
  }
  &__search {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    padding: 0 20px;
    flex-shrink: 1;
    @include respond-to(md) {
      flex-basis: 75%;
    }
    @include respond-to(xs) {
      display: none;
    }
    .form-group {
      margin-bottom: 0;
      flex-grow: 1;
    }
    input {
      height: 100%;
      padding: 0 15px;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(233, 233, 233, 0.2);
      color: #ffffff;
      width: 100%;
      font-size: 12px;
      border-radius: 0;
      &::placeholder {
        color: #fff;
      }
    }
  }
  &__contact {
    flex-basis: 25%;
    min-width: 170px;
    text-align: left;
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
    align-items: center;

    a {
      color: $brand;
      color: #fff;
      font-size: 12px;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    background: #fff;
    position: relative;
    width: 100%;
    height: auto;
    flex-grow: 1;
  }
}
.nav-toggle {
  display: none;
  position: absolute;
  top: 8px;
  right: 15px;
  height: 40px;
  width: 40px;
  z-index: 9;
 @include respond-to(md){
   display: block;
 }

  span {
    display: block;
    width: 30px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    z-index: 99;
    background: $dark;
    transform-origin: 4px 0px;
border-radius: 5px;;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  &:last-child{
    transform-origin: 0% 100%;
  }
  }
}

.logo {
  background: $brand;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  transition: $trans;
  &__link {
    color: #fff;
    font-family: $sec;
    text-decoration: none;
    font-weight: 800;
    font-size: 28px;
    text-align: center;
    line-height: 1.1;
    letter-spacing: 1px;
    transition: $trans;
    @include respond-to(md) {
      font-size: 24px;
    }
  }
  &:hover {
    background: $dark;
    color: $brand;
    a {
      color: $brand;
    }
  }
}
.nav {
  display: flex;
  flex-direction: row;
  position: relative;
  transform: translate(0%, 0%);
  @include respond-to(md){
   position: fixed;
   top: 0;
   right: 0;
   width: 400px;
   max-width: 100%;
   transform: translate(100%, 0%);
   height: 100vh;
   background:  #fff;
   flex-direction: column;
   z-index: 9999;
   transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
   &.is-open{

   transform: translate(0%, 0%);
   }
  }
  &__item {
    background: rgba(255, 255, 255, 0);
    color: $dark;
    font-weight: 500;
    text-decoration: none;
    border-color: rgba(255, 255, 255, 0);
    font-family: $ff;
    padding: 21px 15px 20px;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
    &--close{
      text-align: right;
      padding-right: 40px;
      font-weight: bold;
      color: $brand;
      display: none;
      @include respond-to(md){
       display: block;
      }
    }
    @include respond-to(md){
      position: relative;
      &:before{
        content:'';
        display: block;
        width: 90%;
        margin: 0 auto;
        background:  #eee;
height: 1px;
position: absolute;
bottom: 0;;
      }
    }
    &:hover {
      color: $brand;
    }
  }
}

// .page{
//     padding-top: 90px;
// }

.hero {
  padding-top: 110px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include respond-to(md){
overflow: hidden;
  }
  &__bg {
    position: absolute;
    width: 105%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: url('/images/hero-bg.jpg') top center no-repeat;
    background-size: cover;
    filter: blur(3px);
    z-index: -1;
  }
  & > .site-width {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  &__content {
    flex-basis: 50%;
    padding-top: 70px;
    padding-bottom: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    @include respond-to(md){
      font-size: 40%;
    }
   @include respond-to(xs){
    padding-top: 0px;
    flex-basis: 100%;
    text-align: center;
    padding-bottom: 50px;
   }
  }
  &__imgs {
    flex-basis: 48%;
    padding-bottom: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 43%;
    right: 0;
    z-index: 3;
    @include respond-to(md){
      width: 320px;
      top: 50%;
      left: 50%;
      transform: translate(0%, -50%);
    }
    @include respond-to(xs){
      width: 150px;
      top: 62%;
      left: 60%;
      transform: translate(0%,-50%);
      display: none;
    }
  }
  &__title {
    color: $dark;
    font-family: $sec;
    font-weight: 800;
    font-size: 34px;
    line-height: 1.3;
    background: rgba(255, 255, 255, 0.5);
    // background:  url('/images/dot.png');
    padding: 40px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.12);
    @include respond-to(md){
      font-size: 24px;
    }
  }
  &__btn {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 4;
  }
  &__search-form {
    margin-top: auto;
  }
}
.search-form {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 6;
  @include respond-to(xs){
    flex-wrap: wrap;
  }
  .btn{
    @include respond-to(xs){
      margin: 0 auto;
    }
  }
  &__wrap {
    background: rgba(255, 255, 255, 0.2) url('/images/dot.png');
    // background: rgba(0, 0, 0, 0.2)  url('/images/dot.png');
    display: flex;
    width: 100%;
    padding: 50px 0 50px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
      background: rgba(255, 255, 255, 0.7);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block !important;
      z-index: 2;
    }
  }
  .form-group {
    flex-basis: 20%;
    margin-bottom: 0;
    @include respond-to(xs){
      flex-basis: 50%;
      margin-bottom: 10px;
    }
  }

  .form-control {
    width: 100%;
    background: rgb(124, 124, 124);
    background: #eee;
    border: none;
    // border: 1px solid rgba(0, 0, 0, 0.3);
    // background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(233, 233, 233, 0.2);
    box-shadow: 0 0 1px 1px #565656;
    color: #ffffff;
    // color: rgb(172, 172, 172);
    color: $dark;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    font-size: 15px;
  }
}
.select {
  position: relative;
  padding: 0 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  &:before {
    content: '\f0d7';
    font-family: 'FontAwesome';
    position: absolute;
    right: 25px;
    top: 15px;
    color: $dark;
  }
}
.about-us {
  position: relative;
  overflow: hidden;
  &__img {
    width: 50%;
    position: absolute;
    right: -100%;
    opacity: 0;
    top: 5%;
    @include respond-to(small){
      top: 55%;
    }
    @include respond-to(xs){
      top: 61%;

    }
    img {
      width: 100%;
    }
  }
  .list {
    width: 65%;
    margin-left: 10%;
    @include respond-to(xs){
      width: 100%;
      margin-left: 0%;
      margin-bottom: 80px;
    }
  }
  &__btn {
    width: 65%;
    margin-left: 10%;
    text-align: center;
    margin-top: 60px;
  }
}

.carousel {
  position: relative;
  padding: 50px 0 0;
  &__item {
    text-decoration: none;
    padding: 10px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__descr {
    padding: 23px 28px 22px;
    text-decoration: none;
    text-align: center;
    bottom: 0;
    left: 0;
    width: 100%;
    font-family: $sec;
    color: $brand;
    transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
  }
  &__title {
    color: $brand;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    background: 0 0;
    outline: 0;
    display: inline-block;
    width: 49px;
    height: 49px;
    border: 1px solid #222;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: #222;
    transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    border-color: #fff;
    //    background:  rgba(255,255,255,0.6);
    transform: translateY(-50%);
    z-index: 6;
    &:before {
      font-family: 'carleader';
      font-size: 18px;
      line-height: 1.2;
      color: #fff;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.6);
      border-color: $brand;
      &:before {
        color: $brand;
      }
    }
  }
  .slick-prev {
    left: 12.5%;
    &:before {
      content: '\e916';
    }
  }
  .slick-next {
    right: 12.5%;
    &:before {
      content: '\e917';
    }
  }
}

.gallery {
  padding: 80px 15px;
  max-width: 1300px;
  margin: 0 auto;
  &__menu {
    position: relative;
    z-index: 99;
    padding-bottom: 60px;
    @include respond-to(small) {
      padding-bottom: 0px;
    }

    &-list {
      width: 100%;
      text-align: center;
      padding: 0;
      margin: 20px auto;
      font-size: 0;
    }

    &-item {
      padding: 0 10px;
      text-align: center;
      display: inline-block;

      @include respond-to(small) {
        // width: 100%;
        margin-bottom: 5px;
      }
    }

    &-link {
      width: auto;
      color: #686868;
      font-size: 14px;
      padding: 7px 15px;
      text-decoration: none;
      display: inline-block;
      background: #f1f1f1;
      text-align: center;
      border: 1px solid #fff;
      transition: $trans;
      @include respond-to(small) {
        font-size: 16px;
      }

      &:hover,
      &:active,
      &:focus {
        background: $dark;
        color: #fff;
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.product-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  &.column-5 {
    .product-card {
      width: 20%;
      flex-basis: 20%;
    }
  }
  &.column-4 {
    .product-card {
      width: 25%;
      flex-basis: 25%;
      @include respond-to(md){
        width: 33.3%;
      flex-basis: 33.3%;
      }
      @include respond-to(small){
        width: 50%;
        flex-basis: 50%;
      }
      @include respond-to(xxs){
        width: 100%;
        flex-basis: 100%;
      }
    }
  }
  &.column-3 {
    .product-card {
      width: 33.3%;
      flex-basis: 33.3%;
      @include respond-to(small){
        width: 50%;
        flex-basis: 50%;
      }
      @include respond-to(xxs){
        width: 100%;
        flex-basis: 100%;
      }
    }
  }
  &.column-2 {
    .product-card {
      width: 50%;
      flex-basis: 50%;
    }
  }
}
.product-card {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 15px;
  margin: 0 0 30px;
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 400px;
  @include respond-to(xxs){
    max-width: 100%;
  }
  &__inner {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
  }
  &__img {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    img {
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #e5e5e5;
      height: auto;
      max-width: 100%;
    }
  }
  &__text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 20px;
    opacity: 0;
    background-color: rgba(237, 29, 36, 0.95);
    box-sizing: border-box;
    transition: opacity 0.25s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: auto;
      height: auto;
      margin: 0;
      font-family: $ff;
      font-size: 14px;
      letter-spacing: 0.1em;
      line-height: 2em;
      font-weight: 700;
      text-transform: uppercase;
      outline: 0;
      transition: all 0.2s ease-in-out;
      padding: 12px 16px;
      white-space: nowrap;
      z-index: 3;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 2px;
      background-color: transparent;
      &:hover {
        .btn__line {
          opacity: 0.4;
          -webkit-transform: scaleX(0);
          -ms-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: right;
          -ms-transform-origin: right;
          transform-origin: right;
        }
        .btn__line-hidden {
          transform: scaleX(1);
          opacity: 1;
        }
        .btn__text {
          transform: translateX(calc(33px - 8px));
        }
      }
      &__line-holder {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
      }
      &__line {
        position: relative;
        overflow: hidden;
        opacity: 1;
        transform: scaleX(1);
        transform-origin: right;
        width: calc(33px - 7px);
        height: 1px;
        background-color: #fff;
        transition: 0.18s ease-in-out;
        display: inline-block;
        vertical-align: middle;
        &-hidden {
          position: absolute;
          top: 50%;
          opacity: 0.4;
          transform-origin: left;
          transform: scaleX(0);
          display: inline-block;
          vertical-align: middle;
          width: calc(33px - 7px);
          height: 1px;
          background-color: #fff;
          transition: 0.18s ease-in-out;
        }
      }

      &__text {
        position: relative;
        display: inline-block;
        transition: 0.2s cubic-bezier(0.42, 0, 0.6, 0.93);
        transform: translateX(0);
        padding: 0 18px 0 21px;
      }
      &__icon {
        font-size: 13px;
        width: 10px;
        padding-top: 1px;
        display: flex;
        align-items: center;
        svg {
          width: 100%;
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  &:hover {
    .product-card__text {
      opacity: 1;
    }
  }
  &__info {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 18px 0 38px;
    &-row {
      margin-bottom: 14px;
      text-align: left;
      display: flex;
      justify-content: space-between;
    }
    &-category {
      font-family: $sec;
      font-weight: 800;
      text-transform: uppercase;
      color: $brand;
      font-size: 11px;
      letter-spacing: 0.075em;
      display: inline-block;
      margin: 0;
      width: 65%;
      .separator {
        position: relative;
        display: inline-block;
        margin: 0 15px;
        width: 10px;
        height: 1px;
        top: -1px;
        vertical-align: middle;
        background-color: currentColor;
      }
      a {
        color: $brand;
        font-weight: 800;
      }
    }
    &-rating {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-stars {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      font-size: 11px;
      line-height: 1;
      color: $brand;
      -webkit-font-smoothing: antialiased;
      padding-right: 3px;
    }
    &-title {
      margin: 0;
      position: relative;
      text-align: left;
      z-index: 1;
      display: inline-block;
      width: 50%;
      word-wrap: break-word;
      a {
        font-size: 18px;
        line-height: 1em;
        font-family: $sec;
        text-decoration: none;
        font-weight: 700;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        color: $dark;
        transition: color 0.2s ease-out;
        &:hover {
          color: $brand;
        }
      }
    }
    &-price {
      font-family: $sec;
      font-weight: 700;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      color: $dark;
      font-size: 18px;
      line-height: 1em;
      text-align: right;
      span {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
      }
    }
  }
}
.badge {
  position: absolute;
  display: block;
  top: 14px;
  width: auto;
  margin: 0;
  padding: 0 10px;
  font-family: Heebo, sans-serif;
  line-height: 1.1em;
  font-weight: 500;
  text-transform: uppercase;
  color: #ed1d24;
  font-size: 11px;
  letter-spacing: 0.075em;
  color: #fff;
  line-height: 21px;
  text-align: center;
  z-index: 100;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  &--left {
    left: 14px;
  }
  &--right {
    right: 14px;
  }
  &--red {
    background-color: #ed1d24;
    border: 1px solid #ed1d24;
  }
  &--black {
    background-color: $dark;
    border: 1px solid $dark;
  }
}

.brands {
  padding-top: 35px;
  padding-bottom: 80px;
  @include respond-to(small) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  &__slide {
    padding: 0 15px;
    outline: none; // opacity: 0;
    a {
      outline: none;
    }
    img {
      width: 100%;
      outline: none;
      transform: translateY(-100%);
      opacity: 0;
    }
  }
}

.review {
  // background: $dark;
  overflow: hidden;
  &-slider {
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
    max-width: 800px;
    margin: 20px auto 0;
    &__box {
      background-color: #f1f2f2;
      padding: 25px 25px 25px 225px;
      border-radius: 3px;
      position: relative;
      font-size: 14px;
      text-align: left;
      display: flex;
      padding-left: 15px;
      margin: 4px;
      color: $dark;
      @include respond-to(xs){
        flex-wrap: wrap;
      }
    }
    &__pic {
      height: auto;
      flex-grow: 0;
      position: static;
      padding: 10px;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 190px;
      @include respond-to(xs){
        margin: 0 auto;
      }
      figure {
        overflow: visible;
        text-align: center;
        width: 100%;
        height: 80px;
        img {
          width: 80px;
          display: block;
          margin: auto;
          height: auto;
          border: 5px solid rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          max-width: 80px;
        }
      }
    }
    &__name {
      position: static;
      width: 100%;
      margin-top: 10px;
      text-align: center;
      font-family: $sec;
      left: 95px;
      top: 18px;
      color: $brand;
      font-size: 14px;
      font-weight: 500;
      small {
        color: $dark;
        display: block;
        margin-top: 3px;
        font-size: 75%;
        font-weight: 400;
        line-height: 1;
      }
    }
    &__comment {
      border-left: 1px solid #fff;
      padding-left: 25px;
      font-size: 16px;
      font-style: italic;
      display: flex;
      align-items: center;
      @include respond-to(xs){
        border-left: 0;
        padding-left: 0;
        text-align: center;
      }
    }
  }
  .owl-nav {
    display: none !important;
  }
  .owl-dots {
    text-align: center;
    button {
      display: inline-block;
      zoom: 1;
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      span {
      }
    }
  }
  .owl-theme .owl-dots .owl-dot span {
    background: $dark;
    border-radius: 0;
    width: 30px;
    height: 3px;
  }
  .owl-theme .owl-dots .owl-dot.active span {
    background: $brand;
  }
}

.footer {
  background: #333336;
  color: #fff;
  &__inner {
    display: flex;
    justify-content: space-between;
    @include respond-to(small){
flex-wrap: wrap;
    }
  }
  &__col {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    padding: 5px;
    @include respond-to(small){
      flex-basis: 50%;
      margin-bottom: 10px;
    }
    @include respond-to(xs){
      flex-basis: 100%;
      margin-bottom: 10px;
    }
    &:nth-child(2) {
      flex-basis: 20%;
      margin-left: 5%;
      @include respond-to(small){
        flex-basis: 50%;
        margin-left: 0;
      }
      @include respond-to(xs){
        flex-basis: 100%;
        margin-left: 0;
      }
    }
  }
  &__title {
    margin: 0 0 19px;
    padding: 0;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 27px;
    text-transform: uppercase;
  }
  &__list {
    &-item a {
      font-family: 'Muli', 'sans-serif';
      color: #868686;
      font-size: 14px;
      line-height: 35px;
      font-weight: 400;
      text-decoration: none;
      &:hover {
        color: $brand;
      }
    }
  }
  &__descr {
    padding-bottom: 6px;
    margin-bottom: 0;
    color: #868686;
    line-height: 23px;
    font-weight: 400;
  }
  &__icon {
    width: 20px;
    font-size: 15px;
    display: flex;
    align-items: flex-start;
    position: absolute;
    left: 0;
    top: 6px;
  }
  &__contact {
    &-item {
      color: #868686;
      display: block;
      margin: 16px 0 0;
      padding-left: 30px;
      font-style: normal;
      position: relative;

      address {
        font-style: normal;
        font-family: 'Muli', 'sans-serif';
        color: #868686;
        font-size: 14px;
        font-weight: 400;
      }
      a {
        color: #868686;
        text-decoration: none;
        font-family: 'Muli', 'sans-serif';
        color: #868686;
        font-size: 14px;
        font-weight: 400;
        &:hover {
          color: $brand;
        }
      }
    }
  }
  &__subtitle {
    color: #e4e4e4;
    font-weight: 400;
    line-height: 23px;
    padding-bottom: 4px;
    margin-bottom: 0;
    font-weight: bold;
  }
  p {
    font-family: 'Muli', 'sans-serif';
    color: #868686;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    margin-bottom: 20px;
  }
}

.catalog {
  &__inner {
    display: flex;
  }
}
.sidebar {
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 15px;
}
.widget {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  padding: 25px 20px;
  position: relative;
  margin-bottom: 40px;
  &__title {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 24px;
    padding-bottom: 18px;
    position: relative;
    padding-right: 45px;
    font-size: 16px;
    &:before {
      background: #ff5400 none repeat scroll 0 0;
      content: '';
      display: block;
      height: 12px;
      left: -20px;
      position: absolute;
      top: 9px;
      width: 3px;
    }
    &:after {
      content: '';
      display: block;
      width: 70px;
      height: 1px;
      background: #222222;
      position: absolute;
      bottom: -1px;
      left: 0;
      transition: all 0.3s ease-in-out 0s;
      -webkit-transition: all 0.3s ease-in-out 0s;
      -moz-transition: all 0.3s ease-in-out 0s;
      -ms-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
    }
    span {
      display: inline-block;
      padding-right: 20px;
      font-size: 16px;
      color: #363636;
      position: relative;
      &:before {
        background: #ff5400 none repeat scroll 0 0;
        content: '';
        display: block;
        height: 1px;
        left: 100%;
        margin-top: -3px;
        position: absolute;
        top: 50%;
        width: 30px;
      }
      &:after {
        background: #ff5400 none repeat scroll 0 0;
        content: '';
        display: block;
        height: 1px;
        left: 100%;
        margin-top: 4px;
        position: absolute;
        top: 50%;
        width: 45px;
      }
    }
  }
  &__list {
    &-item {
      color: #565656;
      margin-bottom: 11px;
      padding-bottom: 10px;
      border-bottom: 1px dashed #e8e8e8;
      display: flex;
      justify-content: space-between;
      &:last-child {
        border: none;
        padding: 0;
        margin: 0;
      }
      a {
        padding-left: 25px;
        display: inline-block;
        position: relative;
        color: #565656;
        text-decoration: none;
        &:hover {
          color: $brand;
        }
        &:before {
          content: '\f0a9';
          font-family: FontAwesome;
          font-size: 13px;
          color: #ff5400;
          position: absolute;
          top: 2px;
          left: 0;
          color: #ff5400;
        }
      }
    }
  }
  &-search {
    padding: 0;
    input {
      border: 0 none;
      color: #555;
      font-size: 13px;
      line-height: 24px;
      padding: 13px 20px;
      height: 45px;
      width: 100%;
    }
    button {
      background: 0 0;
      border: medium;
      font-size: 0;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      z-index: 1;
      i {
        color: #555;
        font-size: 14px;
      }
      &:hover {
        color: $brand;
        i {
          color: $brand;
        }
      }
    }
  }
}
.price-slider {
  padding-top: 20px;
  &__range {
    background: #ededed;
    border: none;
    height: 7px;
    position: relative;
    text-align: left;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;

    .ui-slider-range {
      background-color: #ff5400;
      top: 0;
      height: 100%;
      position: absolute;
      z-index: 1;
      font-size: 0.7em;
      display: block;
      border: 0;
      border-radius: 1em;
      transition: none;
    }
    .ui-slider-handle {
      position: absolute;
      z-index: 2;
      width: 1em;
      height: 1em;
      border-radius: 1em;
      cursor: ew-resize;
      outline: 0;
      top: -0.3em;
      margin-left: -0.5em;
      background: #ff5400;
      border: none;
      transition: none;
    }
  }
  &__info {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
  &__button {
    background: none;
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    height: auto;
    line-height: 20px;
    font-weight: 700;
    padding: 0 0 0 10px;
    cursor: pointer;
    color: $brand;

    &:hover {
      color: $dark;
    }
  }
  &__result {
    font-size: 14px;
    text-align: right;
    line-height: 2.4;
    color: #565656;
    font-family: 'Muli';
    font-weight: 300;
    letter-spacing: 0.3px;
    display: flex;
    span {
      font-size: 14px;
      line-height: 2.4;
      color: #565656;
      font-family: 'Muli';
      font-weight: 300;
      letter-spacing: 0.3px;
    }
    input {
      font-size: 14px;
      border: none;
      padding-left: 10px;
      color: #565656;
      font-family: 'Muli';
      font-weight: 300;
      letter-spacing: 0.3px;
      width: auto;
    }
  }
}
.breadcrumb {
  border-bottom: 1px solid #e5e5e5;
  background-color: #f5f5f5;
  padding: 20px 0;
  margin-bottom: 0;
  &__link {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-family: $sec;
    line-height: 1.1em;
    font-weight: 500;
    text-transform: uppercase;
    color: $dark;
    font-size: 11px;
    letter-spacing: 0.075em;
    text-decoration: none;
    &:hover {
      color: $brand;
    }
  }
  &__current {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-family: $sec;
    line-height: 1.1em;
    font-weight: 500;
    text-transform: uppercase;
    color: $brand;
    font-size: 11px;
    letter-spacing: 0.075em;
    text-decoration: none;
  }
  &__separator {
    position: relative;
    vertical-align: middle;
    color: $dark;
    display: inline-block;
    &:after {
      content: '';
      position: relative;
      display: inline-block;
      margin: 0 10px;
      vertical-align: middle;
      width: 20px;
      height: 1px;
      top: -1px;
      left: 0;
      background-color: $dark;
    }
  }
}
.product {
  &__inner {
    display: flex;
  }
  &-imgs {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    margin: 0;
    padding: 0 46px 0 0;
    &__inner {
      position: relative;
      display: inline-block;
      vertical-align: top;
    }
  }
  &-img {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 0 15px;
    float: left;
    padding-top: 30px;
    img {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      border: 1px solid #e5e5e5;
    }
    &:first-child {
      left: 15px;
      width: calc(100% - 30px);
      padding: 0;
    }
    &:not(:first-child) {
      width: 33.33333%;
      padding-top: 30px;
    }
  }
  &-summary {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    padding: 0 0 0 14px;
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  &__title {
    font-weight: 700;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    color: $dark;
    margin: 25px 0;
    font-family: $sec;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    font-size: 45px;
    line-height: 1em;
  }
  &__price {
    position: relative;
    display: block;
    font-family: $sec;
    font-weight: 700;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1;
    color: $dark;
    margin: 9px 0 11px;
  }
  &__rating {
    display: flex;
    margin-bottom: 20px;
    &-stars {
      color: $brand;
      padding-right: 20px;
    }
    &-link {
      color: $text;
      text-decoration: none;
      &:hover {
        color: $brand;
      }
    }
  }
  &__descr {
    color: $text;
    margin-bottom: 20px;
  }
  &__cart {
    display: flex;
    align-items: center;
    &-buttons {
      margin: 0 37px 0 0;
    }
    &-button {
      height: 54px;
    }
  }
  &__meta {
    margin: 36px 0 50px;
    &-title {
      font-family: $sec;
      font-weight: 700;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      color: $dark;
      font-size: 18px;
      line-height: 1em;
      margin: 0 0 25px;
      display: block;
    }
    &-row {
      display: flex;
      margin: 17px 0;
    }
    &-name {
      text-transform: uppercase;
      font-family: $sec;
      line-height: 1.1;
      font-weight: 500;
      color: $brand;
      font-size: 11px;
      letter-spacing: 0.075em;
      padding-right: 20px;
    }
    &-text {
      text-transform: uppercase;
      font-family: $sec;
      line-height: 1.1;
      font-weight: 500;
      color: $text;
      font-size: 11px;
      letter-spacing: 0.075em;
      padding-right: 10px;
    }
    a {
      text-decoration: none;
    }
  }
  &__tabs {
    .nav-tabs {
      position: relative;
      display: inline-block;
      width: 100%;
      vertical-align: middle;
      list-style: none;
      margin: 0;
      padding: 0;
      border: none;
      margin-bottom: 10px;
      li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-family: $sec;

        letter-spacing: 0.025em;
        text-transform: uppercase;
        color: $dark;
        font-size: 18px;
        line-height: 1em;
        a {
          font-weight: 700;
          text-decoration: none;
          position: relative;
          display: inline-block;
          vertical-align: top;
          padding: 0 25px 0 0;
          border: none;
          font-size: 14px;
          &:hover {
            color: $brand;
          }
          &.active {
            color: $brand;
          }
        }
      }
    }
  }
}
.quantity {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  &__input {
    position: relative;
    height: 54px;
    width: 54px;
    padding: 0;
    margin: 0 40px 0 0;
    font-family: $sec;
    line-height: 1.1em;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.075em;
    font-size: 13px;
    outline: 0;
    background-color: $brand;
    border-color: $brand;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border: 1px solid $brand;
    text-align: center;
  }
  &__minus {
    bottom: 0;
    border: 1px solid #e5e5e5;
    border-top: 0;
    border-bottom-right-radius: 2px;
    position: absolute;
    right: 0;
    width: 40px;
    height: 50%;
    line-height: 28px;
    background-color: #fff;
    color: #000;
    border-left: 0;
    cursor: pointer;
    transition: color 0.15s ease-in-out;
    font-family: ElegantIcons;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    &:before {
      content: '\f0d7';
      font-family: 'FontAwesome';
      position: absolute;
      left: 50%;
      top: 50%;
      color: $dark;
      transform: translate(-50%, -50%);
    }
  }
  &__plus {
    top: 0;
    border: 1px solid #e5e5e5;
    border-bottom: 0;
    border-bottom-right-radius: 2px;
    position: absolute;
    right: 0;
    width: 40px;
    height: 50%;
    line-height: 28px;
    background-color: #fff;
    color: #000;
    border-left: 0;
    cursor: pointer;
    transition: color 0.15s ease-in-out;
    font-family: ElegantIcons;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    &:before {
      content: '\f0d8';
      font-family: 'FontAwesome';
      position: absolute;
      left: 50%;
      top: 50%;
      color: $dark;
      transform: translate(-50%, -50%);
    }
  }
}
.comment-list {
  &__title {
    font-family: $sec;
    font-weight: 700;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    color: $dark;
    font-size: 18px;
    line-height: 1em;
    margin: 0 0 25px;
    display: block;
    margin-top: 30px;
  }
  &__item {
    display: flex;
    margin-bottom: 20px;
  }
  &__foto {
    width: 120px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 25px;
    img {
      width: 100%;
    }
  }
  &__rating {
    color: $brand;
  }
  &__meta {
    display: flex;
    color: $text;
    margin-bottom: 15px;
    time,
    span {
      font-weight: 300;
      font-family: $ff;
      color: $text;
      font-size: 16px;
    }
    span {
      display: inline-block;
      padding: 0 7px;
    }
  }
  &__author {
    font-size: 16px;
    font-family: $sec;
    font-weight: 700;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    color: $dark;
  }
  &__descr {
    font-weight: 300;
    font-family: $ff;
    color: $text;
    font-size: 15px;
    p {
      font-weight: 300;
      font-family: $ff;
      color: $text;
      font-size: 15px;
    }
  }
}
