$brand: rgb(255, 84, 0);
$dark: #222222;
$text: #565656;

$trans: all 0.6s ease;
/*
==============================
Fonts
==============================
*/

$ff:   'Muli', sans-serif;
$sec: 'Montserrat', sans-serif;;
$reg: 400;
$med: 500;
$semi-bold: 600;
$bold: 700;
$ext-bold: 800;


