@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome-webfont.eot');
  src: url('/fonts/fontawesome-webfont.eot') format('embedded-opentype'),
    url('/fonts/fontawesome-webfont.woff') format('woff'),
    url('/fonts/fontawesome-webfont.ttf') format('truetype'),
    url('/fonts/fontawesome-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'carleader';
  src:   url('/fonts/carleader.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
